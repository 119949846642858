import './App.css';

function App() {
    return (
        <div className="App">
            here lies a blank page with nothing on it.
        </div>
    );
}

export default App;
